import styled, { css } from 'styled-components';

interface Props {
  padding?: number;
  radius?: number;
  backgroundColor: boolean;
}
const Item = styled.div<Props>`
  padding: ${(props) => (props.padding ? `${props.padding}px 24px` : `16px 24px`)};
  border-radius: ${(props) => (props.radius ? `${props.radius}px` : `10px`)};
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}` : 'fit-content')};
  text-align: center;
`;

const BoxImg = styled.div<Props>`
  padding: ${(props) => props.padding && `${props.padding}px`};
  border-radius: ${(props) => props.radius && props.radius};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${(props) => props.theme.colors.light[1]};
    `};
`;

const FavoriteWrapper = styled.div`
  position: absolute;
  top: -9px;
  right: -12%;
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.theme.colors.light[1]};
  border-radius: 50%;
  box-shadow: 0px 4px 13px 0px #00000014;
  cursor: pointer;
  &.favorite {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.unfavorite {
    display: none;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  top: 28px;
  right: -12%;
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.theme.colors.light[1]};
  border-radius: 50%;
  box-shadow: 0px 4px 13px 0px #00000014;
  cursor: pointer;
  display: none;
  .info-fill {
    display: none;
  }
  .info-empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  padding: 8px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px 4px;
    .tooltip {
      display: block;
    }
  }
  &:hover .unfavorite {
    display: flex;
    justify-content: center;
    align-items: center;
    .star-fill {
      display: none;
    }
    &:hover .star-empty {
      display: none;
    }
    &:hover .star-fill {
      display: block;
    }
  }

  &:hover .info-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover .info-empty {
      display: none;
    }
    &:hover .info-fill {
      display: block;
    }
  }
  .tooltip {
    display: none;
    position: absolute;
    top: 108%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 700;
    width: 200px;
    height: fit-content;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    animation: myOpacity 1s;
    cursor: default;
    .title {
      font-weight: bold;
      text-align: center;
    }
    p {
      margin-block-end: 0;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -10px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 10px solid white;
    }
  }
  @keyframes myOpacity {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default { Item, BoxImg, FavoriteWrapper, InfoWrapper, Wrapper };
