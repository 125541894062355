import styled from 'styled-components';

interface Props {
  error?: boolean;
}

const ContextIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  svg,
  path {
    fill: '#1C495E';
  }
  :hover {
    border: 1px solid #ccd7d6;
  }
`;

const ConversationWrapper = styled.div`
  background-color: #fff;
  height: calc(100vh - 104px);
  padding: 20px;
  border-radius: 13px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const Conversation = styled.div`
  width: 100%;
  height: 42px;
  padding: 10px 12px;
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  .chatsCircle {
    fill: #000;
  }
  :hover {
    background-color: #1f2d3aad;
    color: #fff;
    .chatsCircle {
      fill: #fff;
    }
    svg,
    path {
      fill: #fff;
    }
  }

  &.active {
    background-color: #1f2d3a;
    color: #fff;
    .chatsCircle {
      fill: #fff;
    }
    ${ContextIcon} {
      svg,
      path {
        fill: #fff;
      }
    }
  }
`;

const ConversationTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .ant-input {
    padding: 0 0.2rem;
    border: none;
    border-radius: 0;
    color: white;
    background: transparent;
  }
`;

const SourceItem = styled.a`
  width: 160px;
  padding: 20px;
  border-radius: 8px;
  background-color: #eaeaea33;
  border: 1px solid #b8b8b833;
  cursor: pointer;
  color: #1f2d3a;
  font-size: 16px;
  :hover {
    background-color: #eaeaea80;
    color: #1f2d3a;
  }
`;

const Presentation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding: 5px 0;
`;

const UserMessage = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 12px;
  margin-bottom: 20px;
`;

const BotMessage = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 12px;
  margin-bottom: 20px;
`;

const FilterSpace = styled.div`
  white-space: unset;
  line-height: 1.7;
  ol:first-child {
    margin-block-start: 10px;
  }
  ol {
    padding-inline-start: 20px;
    margin-bottom: 0;
    margin-block-end: 10px;
    margin-block-start: 0;
  }
  ul {
    margin-block-start: 0;
    margin-block-end: 20px;
  }
`;

const SourceWrapper = styled.div`
  padding-left: 36px;
`;

const SourceTitle = styled.div`
  color: #1f2d3a;
`;

const SourceScroll = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const EmptyStateBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const WrapperSelectVersion = styled.div`
  .ant-select {
    width: 120px;
  }
  .ant-select > .ant-select-selector {
    border-radius: 8px;
  }
`;
const LimitMessageBox = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #fdbf00;
  background-color: #fef8e3;
  padding: 14px 20px;
`;

const EmptyMessageBox = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin: 80px auto;
`;

const InputGroup = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
`;

const Textarea = styled.textarea<Props>`
  width: 100%;
  height: auto;
  max-height: 200px;
  padding: 16px 18px;
  border-radius: 8px;
  border: 1px solid #efefef;
  color: #606060;
  outline: none;
  resize: none;
  overflow-y: auto;
  &::placeholder {
    color: ${(props) => (props.error ? 'red' : 'inherit')};
    opacity: ${(props) => (props.error ? '0.6' : '1')};
  }
`;

const Remind = styled.div`
  text-align: center;
  color: #b8b8b8;
  font-size: 12px;
  padding-top: 12px;
`;

const MessageBubble = styled.div`
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  font-size: 16px;
  padding: 8px 14px;
  background-color: #1f2d3a;
  color: #fff;
  width: 100%;
  cursor: pointer;
`;

const InlineCitation = styled.a`
  position: relative;
  text-decoration: none;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  border-width: 1px;
`;

const FeedbackWrapper = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  .like {
    stroke: #757575;
  }
  .unlike {
    fill: #757575;
  }
  :hover .like {
    stroke: #000000;
  }
  :hover .unlike {
    fill: #000000;
  }
  &.activate {
    .like {
      stroke: #000000;
    }
    .unlike {
      fill: #000000;
    }
  }
`;

export default {
  Conversation,
  ConversationWrapper,
  ConversationTitle,
  SourceItem,
  ContextIcon,
  Presentation,
  UserMessage,
  BotMessage,
  SourceWrapper,
  SourceTitle,
  SourceScroll,
  EmptyStateBox,
  LimitMessageBox,
  EmptyMessageBox,
  InputGroup,
  Textarea,
  Remind,
  MessageBubble,
  InlineCitation,
  FilterSpace,
  FeedbackWrapper,
  WrapperSelectVersion
};
