import { css, keyframes } from 'styled-components';
import { styled } from 'src/styled/Theme';

interface Props {
  color?: string;
  regular?: boolean;
  fz?: number;
  give?: boolean;
  fw?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  fm?: string;
  lh?: number;
  textAlign?: string;
  click?: boolean;
  hover?: boolean;
  isEdit?: boolean;
  hidden?: boolean;
  user?: boolean;
  isActive?: boolean;
  animationDelay?: number;
  isApproved?: number;
  isDenied?: boolean;
}
const MainTitle = styled.h2<Props>`
  margin: 0;
  font-family: ${({ fm }) => (fm ? fm : `inherit`)};
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[24])};
  color: ${(props) => props.color || props.theme.colors.dark[3]};
  font-weight: ${(props) => (props.fw ? props.fw : props.theme.fontWeight.bold)};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : `inherit`)};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '')};
  line-height: ${({ lh }) => lh || 'inherit'};
`;
const SubTitle = styled.h4<Props>`
  margin: 0;
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[16])};
  color: ${(props) => props.theme.colors.dark[3]};
  font-weight: ${(props) => props.fw || props.theme.fontWeight.semibold};
  ${({ hover }) =>
    hover &&
    css`
      :hover {
        text-decoration: underline;
        cursor: pointer;
        text-decoration-thickness: 1px;
      }
    `}
`;
const EnterSubTitle = styled.p<Props>`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.3);
`;
const Normal = styled.p<Props>`
  margin: 0;
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[14])};
  font-weight: ${(props) => props.fw || props.theme.fontWeight.regular};
  color: ${(props) => props.color || props.theme.colors.dark[3]};
  cursor: ${({ click }) => (click ? `pointer` : `unset`)};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
  background: ${(props) => props.background || 'transparent'};
  font-family: ${({ fm }) => fm || `inherit`};
  ${({ hover }) =>
    hover &&
    css`
      :hover {
        text-decoration: underline;
        cursor: pointer;
        text-decoration-thickness: 1px;
      }
    `}
`;
const ProjectTitle = styled.div<Props>`
  padding-left: 24px;
  font-size: ${(props) => props.theme.fontSizes[16]};
  color: ${(props) => props.theme.colors.dark[3]};
  font-weight: ${(props) =>
    props.regular ? props.theme.fontWeight.regular : props.theme.fontWeight.semibold};
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -8px;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    background-color: ${(props) => (props.color ? props.color : props.theme.colors.normal[3])};
  }
`;
const ActionText = styled.p<Props>`
  margin: 0;
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[16])};
  color: ${(props) => props.theme.colors.normal[3]};
  font-weight: ${(props) => props.theme.fontWeight.semibold};
  cursor: pointer;
`;
const ContentNew = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: ${(props) => props.theme.fontSizes[16]};
  color: ${(props) => props.theme.colors.dark[3]};
`;
const TinyText = styled.p<Props>`
  margin: 0;
  cursor: ${({ click }) => (click ? `pointer` : `unset`)};
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[12])};
  color: ${(props) => (props.color ? props.color : props.theme.colors.dark[6])};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : `inherit`)};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '')};
  font-weight: ${(props) => (props.fw ? props.fw : 'initial')};
  font-family: ${({ fm }) => fm || `inherit`};
  :hover {
    text-decoration: ${({ hover }) => (hover ? `underline` : `none`)};
  }
  ${({ ml }) => (ml ? `margin-left: ${ml}px` : '')};
`;
const PointText = styled.div<Props>`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes[42]};
  color: ${(props) => (props.give ? props.theme.colors.normal[4] : props.theme.colors.normal[2])};
`;

const TextSpacing = styled.div<Props>`
  font-weight: ${({ fw }) => (fw ? fw : '')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '')};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '')};
  text-transform: capitalize;
  color: #060606;
`;
const ContentComment = styled.div<Props>`
  word-break: break-word;
  padding: 9px 16px 8px 16px;
  margin-right: 20px;
  background-color: ${({ isEdit }) => (isEdit ? `#FFFFFF` : '#F5F5F5')};
  border-radius: 6px;
  text-align: left;
  border: 1px solid #f5f5f5;
`;

const PointRewardContent = styled.div<Props>`
  word-break: break-word;
  padding: 0 0 8px 16px;
  background-color: ${(props) => props.theme.colors.light[1]};
  border-radius: 6px;
  text-align: left;
  position: relative;
  color: ${(props) => props.theme.colors.dark[3]};

  .point-reward-title {
    font-size: 15px;
    line-height: 1.7;
  }

  strong {
    font-weight: 600;
    color: ${(props) => props.theme.colors.dark[2]};
  }
`;

const PointRewardPoint = styled.b<Props>`
  color: ${(props) => props.theme.colors.light[1]};
  background-color: ${(props) => (props.isDenied ? '#F07171' : '#35AE35')};
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 14px;
`;

const PointRewardTime = styled.div<Props>`
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: ${({ isActive }) => (isActive ? `#0B7BFF` : `#606060`)};
  cursor: ${({ click }) => (click ? `pointer` : `unset`)};

  :hover {
    text-decoration: ${({ click }) => (click ? `underline` : `none`)};
  }
`;

const ActivityHistoryContent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  word-break: break-word;
  margin-bottom: 4px;
  background-color: ${(props) => props.theme.colors.light[1]};
  color: ${(props) => props.theme.colors.dark[3]};

  .activity-history-content {
    color: ${(props) => props.theme.colors.dark[3]};
    font-size: 15px;
  }
`;

const InputComment = styled.textarea`
  border-radius: 6px;
  border: 1px solid #efefef;
  width: 100%;
  height: auto;
  padding: 12px;
  display: block;
  overflow: hidden;
  resize: none;
  box-sizing: border-box;
`;
const EditComment = styled.textarea`
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  background-color: #ffffff;
`;
const TextEditDropdown = styled.div`
  padding: 4px 11px;
  color: #060606;
  font-size: 18px;
  background-color: ${(props) => props.theme.colors.light[1]};
  cursor: pointer;
  box-shadow: 0px 7px 29px 0px #64646f33;
`;
const TextHashtag = styled.span<Props>`
  color: ${({ color }) => (color ? `${color}` : '#8B8B8B')};
  font-size: ${({ fz }) => (fz ? `${fz}px` : '15px')};
  margin: 0px 2px 0px 2px;
`;
const TextWillGet = styled.div`
  position: relative;
  padding-right: 33px;
  font-size: ${(props) => props.theme.fontSizes[68]};
  color: ${(props) => props.theme.colors.normal[4]};
  display: inline-block;
  line-height: 0.9;
  &:after {
    content: 'points';
    position: absolute;
    right: 0;
    top: 0;
    font-size: ${(props) => props.theme.fontSizes[10]};
    color: ${(props) => props.theme.colors.normal[4]};
  }
`;
const TextLoadMore = styled.div<Props>`
  display: ${({ hidden }) => (hidden ? `none` : 'block')};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #606060;
  margin: 0px 0px 10px 47px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TextFeeling = styled.span<Props>`
  color: ${(props) => props.theme.colors.dark[5]};
  font-size: 16px;
`;

const TinyViewer = styled.p<Props>`
  margin: 0;
  cursor: ${({ click }) => (click ? `pointer` : `unset`)};
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[12])};
  color: ${(props) => props.theme.colors.dark[6]};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : `inherit`)};
  ${({ hover }) =>
    hover &&
    css`
      :hover {
        color: #000;
        font-weight: 500;
      }
    `}
`;

const ellipsis = keyframes`
  0%   {top: 0px;}
  50%  {top: -4px;}
  100% {top: 0px;}
`;

const DotAnimation = styled.span<Props>`
  position: relative;
  margin: 1px;
  animation-name: ${ellipsis};
  animation-duration: 0.66s;
  animation-iteration-count: infinite;
  animation-delay: ${({ animationDelay }) => animationDelay}s;
`;
const StatusSubTitle = styled.p<Props>`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: ${({ isApproved }) => (isApproved ? '#87C289' : 'rgba(51, 51, 51, 0.3)')};
`;
export default {
  MainTitle,
  Normal,
  ProjectTitle,
  ActionText,
  ContentNew,
  TinyText,
  PointText,
  SubTitle,
  TextSpacing,
  ContentComment,
  PointRewardContent,
  PointRewardTime,
  InputComment,
  TextEditDropdown,
  TextWillGet,
  TextHashtag,
  EditComment,
  TextLoadMore,
  TextFeeling,
  TinyViewer,
  DotAnimation,
  EnterSubTitle,
  StatusSubTitle,
  PointRewardPoint,
  ActivityHistoryContent
};
