import { CONTAINER_MARGIN } from 'src/common/constants';
import { styled } from 'src/styled/Theme';

const TabContainer = styled.div`
  background-color: #fff;
  padding: 0 ${() => `${CONTAINER_MARGIN * 100}%`};
  min-height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgb(72 72 72 / 4%);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  position: sticky;
  top: 64px;
  z-index: 10;

  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-tab {
      margin-right: 16px;
      color: ${(props) => props.theme.colors.dark[3]};
      font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.fontSizes[14])};
      padding: 21px 15px;
      &-btn:focus {
        color: ${(props) => props.theme.colors.dark[3]};
      }
      &-active .ant-tabs-tab-btn {
        color: ${(props) => props.theme.colors.dark[3]};
        font-weight: 500;
      }
      &-tabpane {
        position: relative;
      }
    }
    .ant-tabs-ink-bar {
      height: 3px;
      background: ${(props) => props.theme.colors.normal[3]};
    }
  }
  .tab-space {
    &-noti {
      margin-right: 10px;
    }
  }
`;
const TabCategory = styled.div`
  .ant-tabs {
    &-bar {
      border: 0;
    }
    &-card {
      .ant-tabs-card-bar {
        .ant-tabs-tab {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0;
          border: 0;
          background: transparent;
        }
      }
    }
  }
`;

const TabProfile = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0px 4px 4px 0px rgb(72 72 72 / 4%);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  border-radius: 10px;

  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-tab {
      margin-right: 16px;
      color: ${(props) => props.theme.colors.dark[3]};
      font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.fontSizes[14])};
      padding: 21px 15px;
      &-btn:focus {
        color: ${(props) => props.theme.colors.dark[3]};
      }
      &-active .ant-tabs-tab-btn {
        color: ${(props) => props.theme.colors.dark[3]};
        font-weight: 500;
      }
      &-tabpane {
        position: relative;
      }
    }
    .ant-tabs-ink-bar {
      height: 3px;
      background: ${(props) => props.theme.colors.normal[3]};
    }
  }
  .tab-space {
    &-noti {
      margin-right: 10px;
    }
  }
`;

export default { TabContainer, TabCategory, TabProfile };
