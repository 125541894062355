import { styled } from 'src/styled/Theme';

const CarouselContainer = styled.div`
  .ant-carousel {
    max-width: 560px;
    .slick-dots {
      li {
        height: 8px;
        width: 32px;
        &:not(:last-child) {
          margin-left: 10px;
        }
        button {
          background: ${(props) => props.theme.colors.dark[6]};
          height: 8px;
          width: 16px;
          border-radius: 8px;
          margin: auto;
        }
        &.slick-active {
          button {
            background: ${(props) => props.theme.colors.dark[2]};
            width: 32px;
            margin-right: 5px;
          }
        }
      }
      &-bottom {
        bottom: -30px;
      }
    }
  }
`;
export default { CarouselContainer };
