import { styled } from 'src/styled/Theme';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 192px);
  overflow: hidden;
  border-radius: 13px;
  background-color: #fff;
  .react-transform-wrapper {
    width: 100%;
    height: 100%;
  }
  .node {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .node-content {
    border-radius: 16px;
    text-align: center;
    z-index: 1; /* Ensure node content is above the line */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  line {
    stroke: #000;
  }

  .line {
    position: absolute;
    z-index: -1;
    left: 50%;
  }

  .vertical {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .horizontal {
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .tree-chart {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #nodeForm .ant-select-selector {
    border-radius: 6px;
    border: 1px solid #eaeaea;
  }
`;

const SidebarWrapper = styled.div`
  background-color: #fff;
  height: calc(100vh - 192px);
  padding: 20px;
  border-radius: 13px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const Sidebar = styled.div`
  width: 100%;
  height: 42px;
  padding: 10px 12px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background-color: #1f2d3aad;
    color: #fff;
  }
  &.active {
    background-color: #1f2d3a;
    color: #fff;
  }
`;

const SidebarTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: bold;
  .ant-input {
    padding: 0 0.2rem;
    border: none;
    border-radius: 0;
    color: white;
    background: transparent;
  }
`;

const BtnRemoveAvatar = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-color: red;
  color: white;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default { Container, SidebarWrapper, Sidebar, SidebarTitle, BtnRemoveAvatar };
