import styled, { css } from 'styled-components';

interface Props {
  center?: boolean;
  full?: boolean;
  col?: boolean;
  colRev?: boolean;
  between?: boolean;
  jusend?: boolean;
  justifyEnd?: boolean;
  flexend?: boolean;
  itemend?: boolean;
  flexStart?: boolean;
  itemStart?: boolean;
  itemCenter?: boolean;
  around?: boolean;
  wraper?: boolean;
  position?: string;
  gap?: string;
  cursor?: string;
  fw?: string;
  fs?: string;
  fm?: string;
}

const Flex = styled.div<Props>`
  display: flex;
  position: ${({ position }) => position};
  cursor: ${({ cursor }) => cursor};
  gap: ${({ gap }) => gap};
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
      align-items: center;
    `};
  ${({ jusend }) =>
    jusend &&
    css`
      justify-content: flex-end;
      align-items: center;
    `};
  ${({ justifyEnd }) =>
    justifyEnd &&
    css`
      justify-content: flex-end;
    `};
  ${({ itemStart }) =>
    itemStart &&
    css`
      align-items: flex-start;
    `};
  ${({ itemend }) =>
    itemend &&
    css`
      align-items: flex-end;
    `};
  ${({ itemCenter }) =>
    itemCenter &&
    css`
      align-items: center;
    `};
  ${({ flexend }) =>
    flexend &&
    css`
      justify-content: flex-end;
      align-items: flex-end;
    `};
  ${({ full }) =>
    full &&
    css`
      width: 100%;
      height: 100%;
    `};
  ${({ flexStart }) =>
    flexStart &&
    css`
      justify-content: flex-start;
    `};
  ${({ col }) =>
    col &&
    css`
      flex-direction: column;
    `};
  ${({ colRev }) =>
    colRev &&
    css`
      flex-direction: column-reverse;
    `};
  ${({ between }) =>
    between &&
    css`
      justify-content: space-between;
    `};
  ${({ around }) =>
    around &&
    css`
      justify-content: space-around;
    `};
  ${({ wraper }) =>
    wraper &&
    css`
      flex-wrap: wrap;
    `};
  ${({ fw }) =>
    fw &&
    css`
      font-weight: ${fw};
    `};
  ${({ fs }) =>
    fs &&
    css`
      font-size: ${fs};
    `};
  ${({ fm }) =>
    fm &&
    css`
      font-family: ${fm};
    `};
`;

export default Flex;
