import { imgs } from 'src/static';
import { styled } from 'src/styled/Theme';

const DropImageContainer = styled.div`
  .ant-upload {
    /* height: 324px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; */
    &-picture-card-wrapper {
      position: relative;
      min-height: 324px;
    }
    &-select {
      &-picture-card {
        width: 100%;
        opacity: 0;
        position: absolute;
        z-index: 5;
        display: block !important;
        min-height: 324px;
        top: 0;
        left: 0;
      }
    }
    &-list {
      &-picture-card-container {
        width: 100%;
      }
      &-item {
        min-height: 324px;
        width: 100%;
        border-width: 0;
        .ant-upload-list-item-info {
          background-color: ${(props) => props.theme.colors.dark[3]};
          border-radius: 12px;
          color: white;
        }
      }
    }
  }
  .drop-image {
    &-text {
      color: ${(props) => props.theme.colors.light[1]};
      font-size: ${(props) => props.theme.fontSizes[18]};
    }
  }
  .anticon-picture {
    background-image: url('${imgs.DropDownImg}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 142px;
    margin-top: 70px;
    width: 300px;
    height: 142px;
    &:after {
      content: 'Drag & drop your file into this area or browse for a file to upload';
      color: white;
      font-size: 1rem;
      line-height: 2;
      display: flex;
    }
    svg {
      opacity: 0;
    }
  }
`;
export default DropImageContainer;
