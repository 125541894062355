import { css } from 'styled-components';
import { icons } from 'src/static';
import { styled } from 'src/styled/Theme';
interface Props {
  backgroundColor?: string;
  color?: string;
  give?: boolean;
  ishidden?: boolean;
  size?: number;
  require?: boolean;
  width?: number;
  height?: number;
  click?: boolean;
}
interface SuggestProps {
  active?: boolean;
}
interface CategoryProps {
  active?: boolean;
}
const ColorLabel = styled.label<Props>`
  width: ${(props) => (props.width ? `${props.width}px` : '60px')};
  height: ${(props) => (props.height ? `${props.height}px` : '24px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  user-select: none;
  font-size: ${(props) => props.theme.fontSizes[12]};
  color: ${(props) => props.theme.colors.light[1]};
  background-color: ${(props) => (props.color ? props.color : props.theme.colors.normal[3])};
  font-weight: bold;
`;
const NotiLabel = styled.label<Props>`
  padding: 5px 10px;
  border-radius: 3px;
  user-select: none;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'rgba(235, 87, 87, 0.1)'};
  font-size: ${(props) => props.theme.fontSizes[12]};
  color: ${(props) => (props.color ? props.color : props.theme.colors.normal[1])};
  opacity: ${(props) => (props.ishidden ? 0 : 1)};
  min-width: 30px;
  display: inline-block;
`;
const AlertLabel = styled(NotiLabel)`
  padding: 8px 16px 8px 45px;
  background: rgba(235, 87, 87, 0.1);
  background-image: url('${icons.IconAlert}');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left 16px center;
  font-size: ${(props) => props.theme.fontSizes[14]};
  color: ${(props) => props.theme.colors.normal[1]};
`;
const PointLabel = styled.label<Props>`
  width: 67px;
  height: 31px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.light[1]};
  background-color: ${(props) =>
    props.give ? props.theme.colors.normal[4] : props.theme.colors.normal[2]};
`;
const HashTag = styled.label<Props>`
  padding: 2px 6px;
  height: 20px;
  border-radius: 10px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  background-color: ${(props) => (props.color ? props.color : props.theme.colors.normal[1])};
  font-size: ${(props) => props.theme.fontSizes['11.2']};
  color: ${(props) => props.theme.colors.light[1]};
  cursor: ${(props) => (props.click ? 'pointer' : 'normal')};
`;

const TextLabel = styled.div<Props>`
  color: ${(props) => (props.color ? props.color : props.theme.colors.dark[5])};
  font-size: ${(props) => (props.size ? `${props.size}px` : `16px`)};
  margin-bottom: 8px;
  &:after {
    content: ${(props) => (props.require ? `"*"` : ``)};
    color: red;
    margin-left: 5px;
  }
`;
const SuggestLabel = styled.label<SuggestProps>`
  display: inline-block;
  cursor: pointer;
  padding: 4px 7px;
  border-radius: 4px;
  color: ${(props) => (props.active ? props.theme.colors.light[1] : props.theme.colors.dark[3])};
  background-color: ${(props) =>
    props.active ? props.theme.colors.dark[3] : props.theme.colors.light[1]};
  border: ${(props) => (props.active ? 'none' : `1px solid ${props.theme.colors.light[5]}`)};
  margin: 10px;
`;
const ContainSuggestLabel = styled.div`
  margin: -10px;
`;
const BaseCategoryLabel = styled.label<CategoryProps>`
  width: 154px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.light[5]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes[12]};
`;
const TopicPersonalTab = styled(BaseCategoryLabel)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.normal[5]};
  ${({ active }) =>
    active &&
    css`
      background-color: ${(props) => props.theme.colors.normal[5]};
      color: ${(props) => props.theme.colors.light[1]};
    `}
`;
const EventTab = styled(BaseCategoryLabel)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.normal[4]};
  ${({ active }) =>
    active &&
    css`
      background-color: ${(props) => props.theme.colors.normal[4]};
      color: ${(props) => props.theme.colors.light[1]};
    `}
`;
const SeminarTab = styled(BaseCategoryLabel)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.normal[2]};
  ${({ active }) =>
    active &&
    css`
      background-color: ${(props) => props.theme.colors.normal[2]};
      color: ${(props) => props.theme.colors.light[1]};
    `}
`;
const HaveMoreLabe = styled.div`
  position: fixed;
  top: 50px;
  left: 50%;
  margin-left: -50px;
  color: ${(props) => props.theme.colors.light[1]};
  background-color: ${(props) => props.theme.colors.dark[3]};
  cursor: pointer;
  border-radius: 15px;
  padding: 7px 25px;
  z-index: 100;
  user-select: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  &:hover {
    opacity: 0.9;
  }
`;

const EditDateLabel = styled.div`
  margin-bottom: 16px;
`;

const OptionLabel = styled.div`
  height: 40px;
  width: 150px;
  display: flex;
  padding-left: 14px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  :hover {
    background: rgba(234, 234, 234, 0.3);
  }
`;

export default {
  ColorLabel,
  NotiLabel,
  AlertLabel,
  PointLabel,
  HashTag,
  TextLabel,
  SuggestLabel,
  ContainSuggestLabel,
  TopicPersonalTab,
  EventTab,
  SeminarTab,
  HaveMoreLabe,
  EditDateLabel,
  OptionLabel
};
