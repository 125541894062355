import styled, { css } from 'styled-components';

interface Props {
  src?: string;
  w?: number;
  contentnoti?: string;
  show?: boolean;
  hFull?: boolean;
  filterImg?: boolean;
  index?: number;
  length?: number;
  br?: string;
  disabled?: boolean;
}

interface PropsStatus {
  src?: string;
}

interface PropsAvatar {
  isOnline?: boolean;
  size?: number;
}

const Image = styled.img<Props>`
  src: url(${({ src }) => src});
  width: ${({ w }) => (w ? `${w / 16}rem` : '100%')};
  height: ${({ hFull }) => (hFull ? `100%` : 'auto')};
  border-radius: ${({ br }) => br ?? br};
`;
const IconSidebar = styled.img<Props>`
  src: url(${({ src }) => src});
  width: ${({ w }) => (w ? `${w / 16}rem` : '0')};
  height: auto;
`;
const AvatarContainer = styled.div<PropsAvatar>`
  cursor: pointer;
  width: ${({ size }) => (size ? `${size}px` : '32px')};
  height: ${({ size }) => (size ? `${size}px` : '32px')};
  position: relative;
  background: white;
  border-radius: 50%;
  display: flex;

  &:before {
    content: ${({ isOnline }) => (isOnline ? "''" : 'none')};
    background-color: #6fcf97;
    position: absolute;
    width: 7.2px;
    height: 7.2px;
    border-radius: 50%;
    bottom: -3px;
    right: -3px;
    border: 2px solid #fff;
    box-sizing: content-box !important;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: background-color 0.5s ease;
  }

  .overlay:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  img {
    border-radius: 50%;
  }
`;
const NewThumbnailContainer = styled.div<Props>`
  cursor: pointer;
  width: 100%;
  height: 160px;
  overflow: hidden;
  border-radius: ${(props) => props.br || '6px'};
  background-image: url('${({ src }) => src}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
const Notification = styled.div<Props>`
  cursor: pointer;
  padding-top: 5px;
  height: 32px;
  width: 32px;
  background-position: left bottom;
  background-size: 24px;
  background-repeat: no-repeat;
  position: relative;
  font-size: ${(props) => props.theme.fontSizes[14]};
  color: ${(props) => props.theme.colors.light[1]};
  ${({ contentnoti }) =>
    contentnoti &&
    css`
      &:after {
        content: '${contentnoti}';
        position: absolute;
        top: 0;
        right: 0;
        background-color: #eb5757;
        border-radius: 100%;
        min-width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `};
`;
const ImgComment = styled.img<Props>`
  src: url(${({ src }) => src});
  width: 100%;
  height: auto;
  border-radius: 6px;
`;
const ImgStatusComment = styled.img<Props>`
  src: url(${({ src }) => src});
  width: auto;
  height: auto;
  max-width: 100%;
  border-radius: 6px;
`;
const ImgClose = styled.img<Props>`
  src: url(${({ src }) => src});
  width: ${({ w }) => (w ? `${w / 16}rem` : '0')};
  position: absolute;
  height: auto;
  top: 4px;
  right: 4px;
  z-index: 1;
  :hover {
    cursor: pointer;
  }
  display: ${({ show }) => (show ? `block` : `none`)};
`;
const ImageContentStatus = styled.div<PropsStatus>`
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: 8px;
  z-index: 0;
  overflow: hidden;
  img {
    height: auto;
    width: auto;
    max-width: 50%;
    opacity: 1;
    z-index: 10;
  }
  .bg-content-status {
    background: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    filter: blur(3px);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .bg-blur-status {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    background-color: rgb(0, 0, 0, 0.6);
    mix-blend-mode: multiply;
  }
`;
const RewardDetailContainerImg = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;
const ImageGif = styled.img`
  width: 100%;
  height: 100%;
  object-fit: unset;
`;

const ImagesCover = styled.img<Props>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Medal = styled.div`
  margin-left: 4px;
  width: 100%;
  height: 20px;
  text-align: center;

  img {
    height: 100%;
    background-color: honeydew;
    border-radius: 100%;
  }
`;

const ImageReward = styled.img<Props>`
  src: url(${({ src }) => src});
  width: 100%;
  height: 160px;
  overflow: hidden;
  border-radius: ${(props) => props.br || '6px'};
  object-fit: cover;
  mix-blend-mode: ${(props) => (props.disabled ? 'luminosity' : 'normal')};
  @media screen and (min-width: 1280px) {
    height: 200px;
  }
  @media screen and (min-width: 1536px) {
    height: 240px;
  }
`;

export default {
  Image,
  IconSidebar,
  AvatarContainer,
  Notification,
  NewThumbnailContainer,
  ImgComment,
  ImgClose,
  ImageContentStatus,
  ImgStatusComment,
  RewardDetailContainerImg,
  ImageGif,
  ImagesCover,
  Medal,
  ImageReward
};
