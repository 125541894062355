import { css } from 'styled-components';
import { Flex } from 'src/styled/Layout';
import { styled } from 'src/styled/Theme';

interface Props {
  isStatus?: boolean;
  isHover?: boolean;
}

const EmojiMark = styled(Flex)<Props>`
  width: ${({ isStatus }) => (isStatus ? '32px' : '24px')};
  height: ${({ isStatus }) => (isStatus ? '32px' : '24px')};
  border: none;
  color: #333333;
  margin-right: ${({ isStatus }) => (isStatus ? '9px' : '0')};
  border-radius: 50%;
  background: ${({ isStatus }) => (isStatus ? '#f5f5f5' : 'transparent')};
  outline: none;
  cursor: pointer;
  ${({ isHover }) =>
    isHover &&
    css`
      transition: all 200ms ease-in-out;
      &:hover {
        background-color: ${(props) => props.theme.colors.light[5]};
      }
    `};
`;

export default {
  EmojiMark
};
