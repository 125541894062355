import Button from 'antd/lib/button';
import styled, { css } from 'styled-components';
import { icons } from 'src/static';

interface Props {
  w?: number;
  h?: number;
  isActive?: boolean;
  src?: string;
  disable?: boolean;
  bg?: string;
  bg_hover?: string;
  br?: number;
  size?: number;
  show?: string;
  hover?: boolean;
  noneNumber?: boolean;
  ml?: number;
}
interface IchartProps {
  size?: number;
  rotate?: string;
  hidden?: boolean;
  src?: string;
  icon?: boolean;
}
interface IMentionImgProps {
  hover?: boolean;
  nonMr?: boolean;
  src: string;
  isHover?: boolean;
}
const DefaultBtn = styled.button<Props>`
  min-height: 30px;
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ br }) => (br ? br : 3)}px;
  cursor: pointer;
  user-select: none;
  width: ${({ w }) => (w ? `${w / 16}rem` : '100%')};
  height: ${({ h }) => (h ? `${h / 16}rem` : '1.875rem')};
  font-size: ${(props) => props.theme.fontSizes[16]};
  transition: 0.5s;
  border: none;
  background-color: ${(props) => props.bg || 'inherit'};
  &:hover {
    background-color: ${(props) => props.bg_hover || props.theme.colors.dark[4]};
  }
  :focus {
    outline: 0;
  }
`;
// background-color: ${props => props.theme.colors.light[1]};
const LightBtn = styled(DefaultBtn)`
  border: 1px solid ${(props) => props.border || props.theme.colors.light[5]};
  color: ${(props) => (props.isActive ? props.theme.colors.light[1] : props.theme.colors.dark[4])};
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.dark[8] : props.theme.colors.light[1]};
  &:hover {
    background-color: ${(props) =>
      props.isActive ? props.theme.colors.dark[9] : props.theme.colors.light[5]};
  }
  ${({ disable }) =>
    disable &&
    css`
      border: 0;
      cursor: not-allowed;
      color: ${(props) => props.theme.colors.dark[6]};
      background-color: ${(props) => props.theme.colors.bg};
      &:hover {
        background-color: ${(props) => props.theme.colors.bg};
      }
    `};
`;
const DarkBtn = styled(DefaultBtn)`
  margin-top: ${({ mt }) => (mt ? `${mt}px` : 'unset')};
  color: ${(props) => props.theme.colors.light[1]};
  background-color: ${(props) => props.theme.colors.dark[8]};
  &:hover {
    background-color: ${(props) => props.theme.colors.dark[9]};
  }
  ${({ disable }) =>
    disable &&
    css`
      border: 0;
      color: ${(props) => props.theme.colors.light[2]};
      background-color: ${(props) => props.theme.colors.dark[6]};
      &:hover {
        background-color: ${(props) => props.theme.colors.dark[6]};
      }
      cursor: not-allowed;
    `};
`;
const CreateBtn = styled(DefaultBtn)`
  padding-left: 40px;
  position: relative;
  color: ${(props) => props.theme.colors.light[1]};
  background-color: ${(props) => props.theme.colors.dark[8]};
  margin-left: ${(props) => (props.ml ? props.ml : '0')}px;
  &:hover {
    background-color: ${(props) => props.theme.colors.dark[9]};
  }
  &:before {
    content: '';
    background-image: url('${icons.IconPlus}');
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 20px;
    margin-top: -0.5rem;
  }
`;
const MentionBtn = styled.button<Props>`
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  user-select: none;
  display: flex;
  border: none;
  align-items: center;
  background-color: transparent;
  background-image: url('${({ src }) => src}');
  background-position: left center;
  background-size: 24px;
  background-repeat: no-repeat;
  padding-left: ${({ noneNumber }) => (noneNumber ? `0px` : `34px`)};
  font-size: ${(props) => props.theme.fontSizes[18]};
  color: ${(props) => props.theme.colors.dark[5]};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  transition: 0.5s ease;
  &:focus {
    outline: 0;
  }
  :hover {
    background-color: ${({ hover }) => (hover ? `#F5F5F5` : `transparent`)};
    border-radius: 3px;
  }
`;
const MentionImgBtn = styled.button<IMentionImgProps>`
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  user-select: none;
  border: none;
  background-color: transparent;
  background-image: url('${({ src }) => src}');
  background-position: left center;
  background-size: 24px;
  background-repeat: no-repeat;
  transition: 0.5s ease;
  margin-right: ${({ nonMr }) => (nonMr ? '0' : `10px`)};
  &:focus {
    outline: 0;
  }
  :hover {
    background-color: ${({ hover }) => (hover ? `#F5F5F5` : `transparent`)};
    border-radius: 3px;
  }
`;
export const ButtonSimple = styled.button<IchartProps>`
  display: ${({ hidden }) => (hidden ? `none` : ``)};
  width: ${({ size }) => (size ? `${size}px` : `30px`)};
  height: ${({ size }) => (size ? `${size}px` : `30px`)};
  background-image: url('${(props) => props.src}');
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg);` : `none`)};
  background-repeat: no-repeat;
  background-position: center;
  outline: none;
  background-size: cover;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  transition: 0.5s ease;
  :focus {
    outline: 0;
  }
  :hover {
    background-color: ${({ icon }) => (icon ? `` : `#F5F5F5`)};
    background-image: url('${({ srcHover }) => (srcHover ? srcHover : (props) => props.src)}');
  }
`;

const ButtonIcon = styled.button<Props>`
  position: relative;
  display: ${({ show }) => (show ? `none` : ``)};
  width: ${({ size }) => (size ? `${size}px` : `30px`)};
  height: ${({ size }) => (size ? `${size}px` : `30px`)};
  background-image: url('${(props) => props.src}');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  outline: none;
  background-size: cover;
  background-color: ${({ bg }) => bg};
  border-radius: ${({ br }) => `${br}px`};
  cursor: pointer;
  border: none;
  transition: 0.5s ease;
  :focus {
    outline: 0;
  }
  margin-right: 9px;
  :hover {
    background-color: #f5f5f5;
  }
`;
export const LoadMoreBtn = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  line-height: 30px;
  width: fit-content;
  padding: 10px;
  border: none;
  cursor: pointer;
  :focus {
    outline: 0;
  }
`;

type IMentionStatusProps = {
  isHover: boolean;
  hasSize?: boolean;
};

const MentionStatusBtn = styled.div<IMentionStatusProps>`
  width: ${({ hasSize }) => (hasSize ? '32px' : '24px')};
  height: ${({ hasSize }) => (hasSize ? '32px' : '24px')};
  background-color: ${({ hasSize, theme }) => (hasSize ? theme.colors.light[3] : 'transparent')};
  margin-right: ${({ hasSize }) => (hasSize ? '9px' : '0px')};
  border-radius: 50%;
  user-select: none;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  ${({ isHover }) =>
    isHover &&
    css`
      transition: all 200ms ease-in-out;
      &:hover {
        background-color: ${(props) => props.theme.colors.light[5]};
      }
    `};
  &:focus {
    outline: 0;
  }
`;
const DefaultStepButton = styled.button`
  cursor: pointer;
  width: 192px;
  height: 72px;
  user-select: none;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 41px 15px;
  color: ${(props) => props.theme.colors.dark[1]};
  &:hover {
    transition: 0.5s;
    background-color: ${(props) => props.theme.colors.dark[1]};
    color: ${(props) => props.theme.colors.light[1]};
  }
  &:focus {
    outline: 0;
  }
`;
const NextBtn = styled(DefaultStepButton)`
  padding-right: 80px;
  background-image: url(${icons.IconNextBlack});
  background-position: 70% center;
  &:hover {
    background-image: url(${icons.IconNextWhite});
  }
`;
const PreviousBtn = styled(DefaultStepButton)`
  padding-left: 80px;
  background-image: url(${icons.IconPreviousBlack});
  background-position: 20% center;
  &:hover {
    background-image: url(${icons.IconPreviousWhite});
  }
`;
const ActionBtn = styled.span<Props>`
  border: 1px solid ${(props) => props.theme.colors.light[5]};
  padding: 3px 20px;
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizes[14]};
  color: ${(props) => (props.isActive ? props.theme.colors.dark[3] : props.theme.colors.light[2])};
  background-color: ${(props) => (props.isActive ? props.theme.colors.light[2] : `transparent`)};
  cursor: pointer;
`;

const BtnZoom = styled.button`
  border: none;
  width: 24px;
  height: 24px;
  background: none;
  &:hover {
    color: #337ab7;
  }
  cursor: pointer;
  color: ${(props) => props.theme.colors.light[1]};
  outline: none;
`;

const ButtonOutline = styled(Button)`
  width: 100%;
  height: 40px;
  margin: 5px 0;
  border-radius: 8px;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid #333333;
`;
const ButtonOutlineStartVote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 40px;
  margin-top: auto;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  background-color: ${({ isVoted }) => (isVoted ? '#1230571A' : '#2F80ED1F')};
  &:hover {
    background-color: ${({ isVoted }) => (isVoted ? '#12305726' : '#2F80ED2B')};
  }
  color: ${({ isVoted, theme }) => (isVoted ? '#454E58' : theme.colors.normal[3])};
  font-size: ${(props) => props.theme.fontSizes[16]};
`;

const HyperLinkButton = styled.button`
  width: 50px;
  border: none;
  display: block;
  color: #166de0;
  cursor: pointer;
  text-align: right;
  background-color: transparent;
`;

export default {
  DefaultBtn,
  LightBtn,
  DarkBtn,
  CreateBtn,
  ButtonSimple,
  MentionBtn,
  ButtonIcon,
  MentionImgBtn,
  MentionStatusBtn,
  NextBtn,
  PreviousBtn,
  ActionBtn,
  BtnZoom,
  ButtonOutline,
  HyperLinkButton,
  ButtonOutlineStartVote
};
